import { defineStore } from 'pinia';
import { provideApolloClient } from '@vue/apollo-composable';
import apolloClient from '../composables/graphql';
import { usersStore } from './users';
import { CLIENTS_QUERY_LIST, CLIENT_INSERT } from '~/graphql/clients';
import { cloneDeep } from 'lodash';
import type { Client } from '~/types/client';
import { User } from '~/types/user';

provideApolloClient(apolloClient);

export const clientsStore = defineStore({
  id: 'clients',

  state: () => ({
    clients: [] as Array<Client>,
    selectedClient: null as number | null,
  }),

  getters: {
    getClients(): any {
      return this.clients;
    },
  },

  actions: {
    getClientById(clientId: number): Client | undefined {
      return this.clients.find((client) => client.id === clientId);
    },
    async loadClient(): Promise<Client | void> {
      const user: User = usersStore().me;
      if (!user) return;

      const client_list: { data: { clients: Array<Client> } } = await apolloClient.query({
        query: CLIENTS_QUERY_LIST,
      });

      this.clients = cloneDeep(client_list.data.clients) as Array<Client>;
    },
    async createClient(client: any): Promise<Client | void> {
      const user: User = usersStore().me;
      if (!user || user.role !== 'admin_app') return;

      const new_client: Client = (
        (await apolloClient.mutate({
          mutation: CLIENT_INSERT,
          variables: {
            object: client,
          },
        })) as { data: { insert_clients_one: Client } }
      ).data.insert_clients_one as Client;

      if (new_client) {
        this.clients.push(cloneDeep(new_client) as Client);
      }

      return new_client;
    },
  },
});
