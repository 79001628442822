import { DefaultApolloClient } from '@vue/apollo-composable';
import axios from 'axios';
import qs from 'qs';
import VCalendar from 'v-calendar';
import { setupLayouts } from 'virtual:generated-layouts';
import generatedRoutes from 'virtual:generated-pages';
import { createApp, h, provide } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import apolloClient from '~/composables/graphql';
import type { UserModule, UserModuleContext } from '~/types';
import App from './App.vue';

import './styles/main.css';
import 'uno.css';

axios.defaults.timeout = 60000;
axios.defaults.baseURL = String(import.meta.env.VITE_BACKEND_URL);
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.paramsSerializer = {
  encode: (params: any) => qs.stringify(params, { indices: false }),
};

const routes = setupLayouts(generatedRoutes);

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },
  render: () => h(App),
});

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});
router.onError((error, to) => {
  if ((error as Error).message.includes('Failed to fetch dynamically imported module')) {
    window.location.assign(to.fullPath);
  }
});

app.use(router);
app.use(VCalendar, {});

const ctx: UserModuleContext = { app, router };

const modules = import.meta.glob('./modules/*.ts', { eager: true });
Object.values(modules).forEach((m) => {
  (m as { install?: UserModule }).install?.(ctx);
});

app.config.globalProperties.window = window;

app.mount('#app');
