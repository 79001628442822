import { gql } from '@apollo/client/core';

export const USERS_QUERY_LIST = gql`
  query UsersQuery {
    users {
      site_ids
      role
      picture_url
      last_name
      id
      first_name
      disabled
      email
      client_id
      attachments
      client {
        id
        name
      }
    }
  }
`;

export const USER_QUERY = gql`
  query MyQuery($id: String!) {
    users(where: { id: { _eq: $id } }) {
      email
      first_name
      client_id
      client {
        name
        slug
      }
      id
      last_name
      picture_url
      role
      site_ids
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($object: users_set_input!, $id: String!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: $object) {
      site_ids
      role
      picture_url
      last_name
      id
      first_name
      disabled
      email
      client_id
      attachments
      client {
        id
        name
      }
    }
  }
`;
