import { createPinia } from 'pinia';
import { useFeaturesStore } from '~/stores/features';
import type { UserModule } from '~/types';

// Setup Pinia
// https://pinia.esm.dev/
export const install: UserModule = ({ app }) => {
  const pinia = createPinia();
  app.use(pinia);

  // Specific global store init
  const featureStore = useFeaturesStore();
  featureStore.initFlags();
};
