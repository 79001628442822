export enum FeatureName {
  moduleSidePanel = 'moduleSidePanel',
  publishQuote = 'publishQuote',
};
type FeatureFlags = Record<FeatureName, () => boolean>;

const isStagingOrDev = import.meta.env.MODE !== 'production';

export const useFeaturesStore = defineStore('features', () => {
  const features = ref<FeatureFlags>({} as FeatureFlags);
  const isEnabled = (feature: FeatureName) => !!features.value[feature]();

  function initFlags() {
    features.value = {
      [FeatureName.moduleSidePanel]: () => isStagingOrDev,
      [FeatureName.publishQuote]: () => isStagingOrDev,
    };
  }

  return {
    isEnabled,
    initFlags,
  };
});
