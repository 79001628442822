import { useQuery } from '@tanstack/vue-query';
import axios from 'axios';
import apolloClient from '~/composables/graphql';
import { USERS_QUERY_LIST } from '~/graphql/users';
import { fb_auth } from '~/services/firebase';
import type { User } from '~/types/user';

export function useGetCurrentUser() {
  return useQuery<User>({
    queryKey: ['currentUser'],
    queryFn: async () => {
      const tokenResult = await fb_auth.currentUser?.getIdTokenResult();

      const token = await fb_auth?.currentUser?.getIdToken();
      const response = await axios.get('/v1/users/current', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let user = response.data as User;

      const claims = tokenResult?.claims;
      if (claims && claims['https://hasura.io/jwt/claims']) {
        const hasuraClaims = claims['https://hasura.io/jwt/claims'] as Record<string, string>;
        user = {
          ...user,
          role: hasuraClaims['x-hasura-default-role'],
          client_name: hasuraClaims['x-hasura-client-name'],
          client_slug: hasuraClaims['x-hasura-client-slug'],
        };
      }
      return {
        ...user,
        language: navigator.language.substring(0, 2),
      };
    },
  });
};

export function useGetUsers() {
  return useQuery<User[]>({
    queryKey: ['users'],
    queryFn: async () => {
      const response = await apolloClient.query<{ users: User[] }>({
        query: USERS_QUERY_LIST,
      });
      return response.data.users;
    },
  });
}
