import { ApolloClient, concat, HttpLink, InMemoryCache, type RequestHandler } from '@apollo/client/core';
import { fb_auth } from '~/services/firebase';

const AUTH_TOKEN = 'apollo-token';

export async function getToken(): Promise<string | undefined> {
  const user = fb_auth.currentUser;
  const token: string | undefined = await user?.getIdToken();
  if (token)
    localStorage.setItem(AUTH_TOKEN, token);
  return token;
}

const httpLink = new HttpLink({ uri: import.meta.env.VITE_GRAPHQL_URL as string });

const authMiddleware: RequestHandler = async (operation, forward) => {
  // add the authorization to the headers
  const token = await getToken();
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : 'token bad',
    },
  });
  return forward(operation);
};

const apolloClient = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

export default apolloClient;
